
import { Component, Prop, Vue } from 'vue-property-decorator';

export class WorkItem {
    title: string
    inCharge: string
    technologyList: Array<string>
    workList: Array<string>

    constructor( title: string, inCharge: string, technologyList: Array<string>, workList: Array<string> ) {
        this.title = title
        this.inCharge = inCharge
        this.technologyList = technologyList
        this.workList = workList
    }
}

@Component
export default class WorkListItem extends Vue {
    @Prop()
    private title!: string
    @Prop()
    private inCharge!: string
    @Prop()
    private technologyList!: Array<string>
    @Prop()
    private workList!: Array<string>

    get technologies() {
        return this.technologyList.join( "、" );
    }
}
